import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { defineComponent, onMounted } from '@vue/composition-api';
import { useMeta } from '@/shared/composables/useMeta';
export default defineComponent({
    setup() {
        onMounted(() => {
            useMeta({
                noIndex: true
            });
        });
        return {
            ROUTE_LIST_NAMES
        };
    }
});
